<template>
    <div
        class="rich-text-input"
        :class="{
            'rich-text-input--block': block,
        }"
    >
        <div ref="quillContainer" />
    </div>
</template>

<script>
import Quill from 'quill'
import HtmlEditButton from 'quill-html-edit-button'
import ImageResize from 'quill-image-resize'

const varTemplates = {
    name: '{{ name }}',
    url: '{{ url }}',
}

Quill.register({
    'modules/htmlEditButton': HtmlEditButton,
    'modules/imageResize': ImageResize,
})

export default {
    name: 'RichTextInput',
    props: {
        block: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        expand: {
            type: Boolean,
            default: false,
        },
        placeholder: {
            type: String,
            default: '',
        },
        value: {
            type: String,
            default: '',
        },
        vars: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            quillInstance: null,
        }
    },
    watch: {
        disabled: {
            immediate: true,
            handler() {
                this.$nextTick(() => {
                    this.quillInstance?.enable(!this.disabled)
                })
            },
        },
        value() {
            if (
                this.quillInstance &&
                this.quillInstance.root.innerHTML !== this.value
            ) {
                this.quillInstance.setContents(
                    this.quillInstance.clipboard.convert(this.value),
                    'silent'
                )
            }
        },
    },
    mounted() {
        const toolbarOptions = [
            [{ header: [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['image', 'link'],
        ]

        if (this.expand) {
            toolbarOptions.push(['expand'])
        }

        if (this.vars) {
            toolbarOptions.push([{ vars: Object.values(varTemplates) }])
        }

        this.quillInstance = new Quill(this.$refs.quillContainer, {
            modules: {
                htmlEditButton: {
                    msg: 'HTML',
                    buttonHTML: '</>',
                    okText: this.$t('shared.save'),
                    cancelText: this.$t('shared.cancel'),
                },
                imageResize: {},
                toolbar: {
                    container: toolbarOptions,
                    handlers: {
                        link(value) {
                            const newValue = value ? varTemplates.url : false
                            this.quill.format('link', newValue)
                        },
                        vars(value) {
                            const { index } = this.quill.getSelection()
                            this.quill.insertText(index, value)
                            this.quill.setSelection(index + value.length)
                        },
                    },
                },
            },
            placeholder: this.placeholder,
            theme: 'snow',
        })

        if (this.value) {
            this.quillInstance.setContents(
                this.quillInstance.clipboard.convert(this.value),
                'silent'
            )
        }

        this.quillInstance.on('text-change', () => {
            this.$emit('input', this.quillInstance.root.innerHTML)
        })

        this.$el.querySelector('.ql-expand')?.addEventListener('click', () => {
            this.$emit('expand')
        })

        this.$el.querySelectorAll('.ql-vars .ql-picker-item').forEach(item => {
            item.textContent = item.dataset.value
        })
    },
}
</script>

<style lang="scss">
.rich-text-input {
    background-color: $color-gray-lighter-new;
    border-radius: 4px;
    color: $color-text-new;

    &--block {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
    }

    .ql-toolbar {
        border: none;
        border-bottom: 1px solid $color-gray-light-new;

        .ql-formats {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .ql-container {
        border: none;
        font-size: 14px;
        font-family: 'IBM Plex Sans', sans-serif;
    }

    .ql-editor {
        &.ql-blank {
            &::before {
                color: rgba(0, 0, 0, 0.4);
                font-style: normal;
            }
        }
    }

    .ql-expand {
        &:after {
            content: '↹';
        }
    }

    .ql-vars {
        &.ql-picker {
            width: 60px;
        }

        .ql-picker-label {
            &::before {
                content: 'Vars';
            }
        }
    }
}
</style>
