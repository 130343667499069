<template>
    <VTimepicker
        auto-scroll
        class="time-input"
        :class="{
            'time-input--block': block,
            'time-input--full': full,
        }"
        format="HH:mm"
        hide-clear-button
        lazy
        v-bind="$attrs"
        v-on="listeners"
    />
</template>

<script>
import VTimepicker from 'vue2-timepicker'

export default {
    name: 'TimeInput',
    components: {
        VTimepicker,
    },
    props: {
        block: {
            type: Boolean,
            default: false,
        },
        full: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        listeners() {
            return {
                ...this.$listeners,
                input: e => {
                    if (typeof e === 'string') {
                        e = e.replace(/HH/g, '00').replace(/mm/g, '00')
                    } else if (!e.HH) {
                        e.HH = '00'
                    } else if (!e.mm) {
                        e.mm = '00'
                    }

                    this.$emit('input', e)
                },
            }
        },
    },
}
</script>

<style lang="scss">
.time-input {
    &.time-picker {
        width: auto;

        .display-time {
            padding: 12px 10px;
            border: none;
            background-color: $color-gray-lighter-new;
            line-height: 16px;
            font-size: 14px;
            font-family: 'IBM Plex Sans', sans-serif;
            outline: none;
        }

        .dropdown {
            margin-top: 16px;
            box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
            border: none;
            border-radius: 8px;
            font-family: 'IBM Plex Sans', sans-serif;
            overflow: hidden;

            .select-list {
                .minutes {
                    border: none;
                }

                li {
                    &:not(.hint):not([disabled]):hover {
                        background-color: $color-gray-lighter-new;
                    }

                    &.active {
                        background: $color-gray-lighter-new;
                        font-weight: 700;
                        color: $color-text-new;

                        &:not([disabled]) {
                            &:focus,
                            &:hover {
                                background: $color-gray-lighter-new;
                                color: $color-text-new;
                            }
                        }
                    }

                    &.hint {
                        color: $color-gray-darker;
                    }
                }
            }
        }
    }

    &--block {
        &.time-picker {
            .display-time {
                padding: 10px 12px;
                background-color: #fff;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
                border-radius: 8px;
                line-height: 20px;
            }
        }
    }

    &--full {
        &.time-picker {
            width: 100%;
        }
    }
}
</style>
